@import 'style/index.scss';

.about-top {
    margin-top: 3.5rem;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    &__title {
      width: 100%;
      font-size: 3.25rem;
      color: $codGray;
    }
    &__image {
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__time {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }

    &__years {
      width: 250px;
      height: 250px;
      margin-left: auto;
      margin-right: auto;
      background-color: $codGray;
      color: $white;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 2rem;
      font-weight: bold;
      box-shadow: 0px -2px 30px 7px rgba($trout, 0.81);
      span {
        font-size: 4.5rem;
        line-height: 1;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 3rem;
      }
    }
    &__days,&__hours,&__minutes,&__seconds {

      width: 250px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;
      padding-bottom: 0.5rem;
      color: $codGray;
      font-weight: bold;
    }

    &__days {
      span {
        font-size: 2.5rem;
        padding-right: 0.75rem;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 2.4rem;
      }
    }
    &__hours {
      span {
        font-size: 1.75rem;
        padding-right: 0.75rem;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.65rem;
      }
    }
    &__minutes {
      span {
        font-size: 1.2rem;
        padding-right: 0.75rem;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 1.15rem;
      }
    }
    &__seconds {
      span {
        font-size: 1.05rem;
        padding-right: 0.75rem;
      }
      p {
        margin-top: 0;
        margin-bottom: 0;
        font-size: 0.95rem;
      }
    }

    &__spent {
      color: $codGray;
      margin-top: 1.55rem;
      font-size: 1.35rem;
      font-weight: bold;
      font-size: 1.5rem;
      letter-spacing: 0.3px;
      @include text-shadow(2px,3px, 10px, rgba($darkBlack, .9));
    }

    &__desc {
      display: flex;
      flex-direction: column;
      h2 {
        color: $codGray;
        font-size: 2.5rem;
        margin-top: 0;
        text-align: left;
        width: 100%;
        border-bottom: 2px solid $trout;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }
      p {
        max-width: 700px;
        font-size: 1.25rem;
        &:first-of-type {
          font-size: 1.45rem;
          color: rgba($color: $trout, $alpha: 0.7);
          @include media-breakpoint-down(md) {
            font-size: 1.15rem;
          }
        }
        @include media-breakpoint-down(md) {
          font-size: 1.15rem;
        }
      }
    }
    &__content {
      margin: 0 auto;
      max-width: 650px;
      padding-bottom: 3rem;
      p {
        line-height: 1.45;
      }
    }
    &__lodaing {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
}
@keyframes withblur {
  0% {
    opacity: 0;
    filter: blur(3px);
  }
  30% {
    opacity: 0.1;
    filter: blur(2.5px);
  }
  60% {
    opacity: 0.3;
    filter: blur(1.5px);
  }
  66% {
    opacity: 0.5;
    filter: blur(1.1px);
  }
  80% {
    opacity: 0.7;
    filter: blur(1px);
  }
  100% {
    opacity: 1;
    filter: blur(0px);
  }
}
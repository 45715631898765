@keyframes backEndTech {
  0% {
    content: "Node.js";
  }
  50% {
    content: "Express.js";
  }
  75% {
    content: "MongoDB";
  }
  100% {
    content: "Socket IO";
  }
}
@keyframes frontEndTech {
  0% {
    content: "HTML";
  }
  10% {
    content: "CSS";
  }
  20% {
    content: "Java Script";
  }
  30% {
    content: "React";
  }
  40% {
    content: "Redux";
  }
  50% {
    content: "Vue";
  }
  60% {
    content: "Vuex";
  }
  70% {
    content: "TypeScript";
  }
  70% {
    content: "Jest";
  }
  90% {
    content: "Socket IO";
  }
  100% {
    content: "Git";
  }
}
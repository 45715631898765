@import 'style/index.scss';

.contact {
  background-color: $codGray;
  height: 100%;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  align-content: center;
  overflow-y: auto;
  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}
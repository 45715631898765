@import 'style/index.scss';

  .contact-form {
    background-color: rgba($color: $white, $alpha: 0.5);
    box-shadow: 0px -2px 16px 10px rgba($codGray, 0.85);
    border-radius: 5px;
    width: 85%;
    max-width: 500px;
    margin: auto;
    opacity: 0.9;
    padding: 1rem;
    margin-top: 3rem;
    @include media-breakpoint-down(md){
      width: 90%;
      padding: 1rem 0.55rem 1.6rem 0.55rem;
    }
    @include media-breakpoint-down(sm){
      margin-top: 2rem;
      width: 100%;
    }
    form {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;
      @include media-breakpoint-down(sm){
        padding: 0.5rem;
      }
      label {
        display: flex;
        justify-content: flex-start;
        @include media-breakpoint-down(md){
          font-size: 0.9rem;
        }
        span {
          margin-bottom: 1.25rem;
          text-align: left;
        }
      }
      button {
        margin-top: 25px;
      }
      .text-field {
        padding-top: 1rem;
        &:first-of-type {
          padding-top: 0;
        }
        &__error {
          text-align: left;
        }
      }
    }
  }
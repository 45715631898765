@import 'style/variables/index';
@import 'style/custom/index';

html,
body {
  margin: 0;
  padding: 0;
  background-color: $white;
  font-family: "Roboto", sans-serif;

  .blur {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    animation: withblur 0.6s linear;
  }
  .Toastify__toast {
    &--success {
      background-color: $codGray;
      color: $white;
    }
  }
}
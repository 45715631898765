@import 'style/index.scss';

.button {
  display: inline-block;
  font-weight: 400;
  color: $white;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: 0px 8px 20px rgba($color: $ghost, $alpha: 1.0);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &.primary {
    background-color: $codGray;
    color: $ghost;
    &:hover {
      background-color: $darkBlack;
    }
  }
  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
    &:hover {
      background-color: $codGray;
    }
  }
}
@import 'style/index.scss';

.about-bottom {
    margin-top: 3.5rem;
    height: 100%;
    width: 90%;
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    &__title {
      width: 100%;
      font-size: 3.25rem;
      color: $white;
      // @include text-shadow-white;
    }
    &__image {
      display: flex;
      justify-content: center;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }


    &__me {
      background-image: url('../../../../assets/image/me.jpeg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      border-radius: 5px;
      width: 100%;
      max-height: 500px;
      max-width: 500px;
      box-shadow: 0px -2px 16px -1px rgba($white, 0.61);
    }

    &__desc {
      display: flex;
      flex-direction: column;
      h2 {
        color: $white;
        font-size: 2.5rem;
        margin-top: 0;
        text-align: left;
        width: 100%;
        border-bottom: 2px solid $saffron;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }
      p {
        color: $white;
        max-width: 700px;
        font-size: 1.25rem;
        &:first-of-type {
          font-size: 1.45rem;
          color: rgba($color: $white, $alpha: 0.7);
          @include media-breakpoint-down(md) {
            font-size: 1.15rem;
          }
        }
        @include media-breakpoint-down(md) {
          font-size: 1.15rem;
        }
      }
    }
    &__content {
      margin: 0 auto;
      max-width: 650px;
      padding-bottom: 3rem;
      p {
        line-height: 1.45;
        strong {
          border-bottom: 2px solid $saffron;
        }
      }
    }
}
@import 'style/index.scss';

.about {
  &__togle {
    position: absolute;
    top: 200px;
    right: 30px;
    z-index: 999999;
    display: flex;
    justify-content: space-between;
    width: 220px;
    span {
      max-width: 48%;
      padding: 0.5rem;
      cursor: pointer;
    }
    &-personal {
      background-color: tomato;
    }
    &-professional {
      background-color: wheat;
    }
  }
  .about__wrapper {
    position: relative;
    width: 100%;
    min-height: 55vh;
    overflow: hidden;
    height: 100vh;
    @include media-breakpoint-down(sm){
      padding-top: 2rem;
    }
  }
  &__title {
    font-size: 4rem;
    &--black {
      color: $darkBlack;
      @include text-shadow(3px,2px, 9px, rgba($darkBlack, .9));
    }
    &--white {
      color: $white;
      @include text-shadow(3px,2px, 9px, $white);
    }
    @include media-breakpoint-down(sm){
      padding: 0 2rem;
      font-size: 2.5rem;
    }
  }

  &__layer {
    position: absolute;
    width: 100vw;
    min-height: 55vh;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__info {
    position: absolute;
    width: 100vw;
    min-height: 55vh;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
    height: 100%;
  }
  &__top {
    z-index: 1;
    height: 100vh;
    width: 100%;
    background: $white;
    color: $codGray;
  }

  &__bottom {
    background: $codGray;
    z-index: 2;
    height: 100vh;
    width: 0;
    animation: widthpage 1.5s linear;
    @include media-breakpoint-down(md){
      width: 0!important;
    }
  }
}
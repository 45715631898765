@mixin floating-label {
  display: flex;
  flex-flow: column-reverse;

  label, input, textarea {
    transition: all 0.2s;
    touch-action: manipulation;
    font-size: 0.8rem; }

  input:focus, textarea:focus {
    outline: 0; }

  input:placeholder-shown + label {
    cursor: text;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(10px, 25px) scale(1.2);
    color: rgba($color: $codGray, $alpha: 0.8); }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit; }
  ::-moz-placeholder {
    opacity: 0;
    transition: inherit; }
  ::-ms-input-placeholder {
    opacity: 0;
    transition: inherit; }
  -moz-placeholder: {
    opacity: 0;
    transition: inherit; }

  input:focus::-webkit-input-placeholder {
    opacity: 1; }
  input:focus::-moz-placeholder {
    opacity: 1; }
  input:focus::-ms-input-placeholder {
    opacity: 1; }
  input:focus:-moz-placeholder {
    opacity: 1; }

  input:not(:placeholder-shown) + label,
  input:focus + label {
    transform: translate(0, -5px) scale(1);
    color: inherit; }

  textarea:placeholder-shown + label {
    cursor: text;
    max-width: 75%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transform-origin: left bottom;
    transform: translate(10px, 32px) scale(1.3);
    color: rgba($color: $codGray, $alpha: 0.8); }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit; }
  ::-moz-placeholder {
    opacity: 0;
    transition: inherit; }
  ::-ms-input-placeholder {
    opacity: 0;
    transition: inherit; }
  -moz-placeholder: {
    opacity: 0;
    transition: inherit; }

  textarea:focus::-webkit-input-placeholder {
    opacity: 1; }
  textarea:focus::-moz-placeholder {
    opacity: 1; }
  textarea:focus::-ms-input-placeholder {
    opacity: 1; }
  textarea:focus:-moz-placeholder {
    opacity: 1; }

  textarea:not(:placeholder-shown) + label,
  textarea:focus + label {
    transform: translate(0, -5px) scale(1);
    color: inherit; } }

@import 'style/index.scss';

.contact-map {
  &__text {
    text-align: center;
    z-index: 10;
    position: absolute;
    top: 5%;
    left: 0;
    right: 0;
    font-weight: bold;
    padding: 10px;
    @include media-breakpoint-down(sm){
      padding: 1rem 1rem 2rem 1rem;
    }
    &-title {
      font-size: 2.25rem;
      color: $white;
      @include text-shadow(3px,2px, 9px, $white);
      width: 80%;
      margin: 1.55rem auto;
      @include media-breakpoint-down(md){
        font-size: 1.85rem;
        max-width: 500px;
      }
      @include media-breakpoint-down(sm){
        font-size: 1.35rem;
      }
    }
    &-subtitle {
      font-size: 1.65rem;
      color: $white;
      @include text-shadow(3px,2px, 9px, $white);
      @include media-breakpoint-down(md){
        font-size: 1.50rem;
      }
      @include media-breakpoint-down(sm){
        font-size: 1.20rem;
      }
    }
  }
  &__content {
    opacity: 0.65;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
@import 'style/index.scss';

.home {
  .home__wrapper {
    position: relative;
    width: 100%;
    min-height: 55vh;
    overflow: hidden;
    height: 100vh;
  }

  &__layer {
    position: absolute;
    width: 100vw;
    min-height: 55vh;
    overflow: hidden;
  }

  &__content-wrap {
    position: absolute;
    width: 100vw;
    min-height: 55vh;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  &__content-body {
    width: 25%;
    position: absolute;
    top: 50%;
    text-align: center;
    transform: translateY(-50%);
    color: $white;
  }

  &__frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 25px;
    text-align: center;
    p {
      margin-top: 0;
      margin-bottom: 1.25rem;
    }
    &--white {
      font-size: 5rem;
      color: $white;
      border-radius: 5px;
      @include text-shadow(2px,2px, 9px, rgba($white, .9));
      @include media-breakpoint-down(md) {
        @include text-shadow(3px,2px, 6px, rgba($white, .9));
      }
    }
    &--black {
      border-radius: 5px;
      color: $codGray;
      font-size: 5rem;
      @include text-shadow(3px,5px, 9px, rgba($darkBlack, .9));
      @include media-breakpoint-down(md) {
        @include text-shadow(3px,2px, 6px, rgba($darkBlack, .9));
      }
    }
    @include media-breakpoint-down(md) {
      padding: 10px;
      width: 100%;
      p {
        font-size: 3.55rem;
      }
      span {
        font-size: 3.55rem;
      }
    }
    @include media-breakpoint-down(sm) {
      p {
        font-size: 2.25rem;
      }
      span {
        font-size: 2.25rem;
      }
    }
    @include media-breakpoint-down(xs) {
      p {
        font-size: 1.95rem;
      }
      span {
        font-size: 1.95rem;
      }
    }
  }

  &__layer h2 {
    font-size: 2em;
  }

  &__bottom {
    background: $codGray;
    z-index: 1;
    height: 100vh;
  }

  &__content-body {
    &--bottom {
      left: 100px;
      top: 15%;
      color: $codGray;
      text-align: left;
    }
    &--top {
      right: 30px;
      top: 80%;
      color: $codGray;
      text-align: left;
    }
  }

  &__top {
    background: $white;
    color: $codGray;
    z-index: 2;
    width: 50vw;
    height: 100vh;
    border-right: 5px solid $saffron;
  }
  &__skills--bottom {
    max-width: 100px;
    left: 120px;
    position: absolute;
    top: 110px;
    text-align: center;
    color: $codGray;
    border-bottom: 2px solid $saffron;
    &:after {
      content: "";
      font-weight: bold;
      font-size: 18px;
      animation: frontEndTech 7s linear infinite;
    }
  }
  &__skills--top {
    max-width: 150px;
    right: 120px;
    position: absolute;
    bottom: 110px;
    text-align: center;
    color: $white;
    border-bottom: 2px solid $saffron;
    &:after {
      content: "";
      font-weight: bold;
      font-size: 18px;
      animation: backEndTech 4s linear infinite;
    }
  }

  &__top {
    will-change: transform;
    transform: skew(-30deg);
    margin-left: -950px;
    width: calc(50vw + 1000px);
    @include media-breakpoint-down(md) {
      width: calc(50vw + 950px) !important;
    }
  }

  &__top .home__content-wrap {
    transform: skew(30deg);
    margin-left: 950px;
  }
}
